import React from "react";
import LocalizedLink from "../localizedLink";
import useTranslationsCat from "../useTranslationsCat";
import Styles from "./Footer.module.scss";

const FooterCategoryPC = () => {
const {
thermometers,
digitalThermometers,
dataloggersTemperature,
analogThermometers,
thermographs,
remoteSensingDialThermometers,
bimetalThermometers,
standardGlassThermometers,
glassThermometers,
thermocouples,
resistanceTemperatureDetectors,
thermohygrometers,
digitalThermohygrometers,
dataloggersTemperatureHumidity,
transmitters,
dialThermohygrometers,
thermohygrographs,
psychrometers,
anemometers,
heatStressMonitors,
infraredThermometers,
barometers,
digitalBarometer,
dialBarometers,
barograph,
recorders,
indicators,
weatherInstruments,
windSpeedDirectionIndicator,
anemometersWeatherInstruments,
rainGauge,
instrumentScreens,
moistureMeters,
soilPhMetersSaltMetersCo2MetersOtheres,
refractometers,
phMeters,
timers,
timersSecondLayer,
optionalProbes,
forSkL751,
forSkL754,
forSkL200TIISeries,
forSkL200th2aSeries,
forSkL400tSk1110Sk1120Sk7000PRTII,
forSk1260,
forSk1250MCIIISk1250MCIIIa,
forSk270wpSeries,
forSk250wp2Series,
forSk100wp,
forSk810pt,
forSkRhcSeries,
forSkM460TSkM350T,
forSk610ph2,
forSk660phSeries,
forSk620ph2Sk650ph,
forSk620ph,
chartsAndPens,
forSigma2Thermohygrographs,
forAurora903Thermohygrograph,
forSigma2Thermograph,
forSigma2Hygrograph,
forSigma2Barograph,
forSigma2RemoteThermographs,
forMiniCubeThermohygrograph,
forMiniStarThermohygrograph,
forMiniAlphaThermohygrograph,
forEventRecorder,
forLmmcTemperatureRecorder,
forVariousRecorders,
cartridgePens,
otherAccessories,
discontinuedProducts,
} = useTranslationsCat();
return(
<ul className={Styles.footercategory}>
<li>
<input type="checkbox" id="footer-thermometers" className={Styles.acInput}/>
<label htmlFor="footer-thermometers" className={Styles.acLabel} >{thermometers}</label>
<ul className={Styles.footercategory2}>
<li>
<LocalizedLink to="/categories/thermometers/digital-thermometers/"  className="init-right line">{digitalThermometers}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/dataloggers-temperature/"  className="init-right line">{dataloggersTemperature}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/analog-thermometers/"  className="init-right line">{analogThermometers}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/thermographs/"  className="init-right line">{thermographs}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/remote-sensing-dial-thermometers/"  className="init-right line">{remoteSensingDialThermometers}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/bimetal-thermometers/"  className="init-right line">{bimetalThermometers}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/standard-glass-thermometers/"  className="init-right line">{standardGlassThermometers}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/glass-thermometers/"  className="init-right line">{glassThermometers}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/thermocouples/"  className="init-right line">{thermocouples}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermometers/resistance-temperature-detectors/"  className="init-right line">{resistanceTemperatureDetectors}</LocalizedLink>
</li>
</ul>
</li>
<li>
<input type="checkbox" id="footer-thermohygrometers" className={Styles.acInput}/>
<label htmlFor="footer-thermohygrometers" className={Styles.acLabel} >{thermohygrometers}</label>
<ul className={Styles.footercategory2}>
<li>
<LocalizedLink to="/categories/thermohygrometers/digital-thermohygrometers/"  className="init-right line">{digitalThermohygrometers}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermohygrometers/dataloggers-temperature-Humidity/"  className="init-right line">{dataloggersTemperatureHumidity}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermohygrometers/transmitters/"  className="init-right line">{transmitters}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermohygrometers/dial-thermohygrometers/"  className="init-right line">{dialThermohygrometers}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermohygrometers/thermohygrographs/"  className="init-right line">{thermohygrographs}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/thermohygrometers/psychrometers/"  className="init-right line">{psychrometers}</LocalizedLink>
</li>
</ul>
</li>
<li>
<LocalizedLink to="/categories/anemometers/" className="init-right line">{anemometers}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/heat-stress-monitors/" className="init-right line">{heatStressMonitors}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/infrared-thermometers/" className="init-right line">{infraredThermometers}</LocalizedLink>
</li>
<li>
<input type="checkbox" id="footer-barometers" className={Styles.acInput}/>
<label htmlFor="footer-barometers" className={Styles.acLabel} >{barometers}</label>
<ul className={Styles.footercategory2}>
<li>
<LocalizedLink to="/categories/barometers/digital-barometer/"  className="init-right line">{digitalBarometer}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/barometers/dial-barometers/"  className="init-right line">{dialBarometers}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/barometers/barograph/"  className="init-right line">{barograph}</LocalizedLink>
</li>
</ul>
</li>
<li>
<LocalizedLink to="/categories/recorders/" className="init-right line">{recorders}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/indicators/" className="init-right line">{indicators}</LocalizedLink>
</li>
<li>
<input type="checkbox" id="footer-weather-instruments" className={Styles.acInput}/>
<label htmlFor="footer-weather-instruments" className={Styles.acLabel} >{weatherInstruments}</label>
<ul className={Styles.footercategory2}>
<li>
<LocalizedLink to="/categories/weather-instruments/wind-speed-direction-indicator/"  className="init-right line">{windSpeedDirectionIndicator}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/weather-instruments/anemometers-weather-instruments/"  className="init-right line">{anemometersWeatherInstruments}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/weather-instruments/rain-gauge/"  className="init-right line">{rainGauge}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/weather-instruments/instrument-screens/"  className="init-right line">{instrumentScreens}</LocalizedLink>
</li>
</ul>
</li>
<li>
<LocalizedLink to="/categories/moisture-meters/" className="init-right line">{moistureMeters}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/soil-ph-meters-salt-meters-co2-meters-otheres/" className="init-right line">{soilPhMetersSaltMetersCo2MetersOtheres}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/refractometers/" className="init-right line">{refractometers}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/ph-meters/" className="init-right line">{phMeters}</LocalizedLink>
</li>
<li>
<input type="checkbox" id="footer-timers" className={Styles.acInput}/>
<label htmlFor="footer-timers" className={Styles.acLabel} >{timers}</label>
<ul className={Styles.footercategory2}>
<li>
<LocalizedLink to="/categories/timers/timers-second-layer/"  className="init-right line">{timersSecondLayer}</LocalizedLink>
</li>
</ul>
</li>
<li>
<input type="checkbox" id="footer-optional-probes" className={Styles.acInput}/>
<label htmlFor="footer-optional-probes" className={Styles.acLabel} >{optionalProbes}</label>
<ul className={Styles.footercategory2}>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-l751/"  className="init-right line">{forSkL751}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-l754/"  className="init-right line">{forSkL754}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-l200TII-Series/"  className="init-right line">{forSkL200TIISeries}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-l200th2a-series/"  className="init-right line">{forSkL200th2aSeries}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-l400t-sk-1110-sk-1120-sk-7000PRTII/"  className="init-right line">{forSkL400tSk1110Sk1120Sk7000PRTII}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-1260/"  className="init-right line">{forSk1260}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-1250MCIII-sk-1250MCIIIa/"  className="init-right line">{forSk1250MCIIISk1250MCIIIa}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-270wp-series/"  className="init-right line">{forSk270wpSeries}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-250wp2-series/"  className="init-right line">{forSk250wp2Series}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-100wp/"  className="init-right line">{forSk100wp}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-810pt/"  className="init-right line">{forSk810pt}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-rhc-series/"  className="init-right line">{forSkRhcSeries}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-m460-t-sk-m350-t/"  className="init-right line">{forSkM460TSkM350T}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-610ph-2/"  className="init-right line">{forSk610ph2}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-660ph-series/"  className="init-right line">{forSk660phSeries}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-620ph2-sk-650ph/"  className="init-right line">{forSk620ph2Sk650ph}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/optional-probes/for-sk-620ph/"  className="init-right line">{forSk620ph}</LocalizedLink>
</li>
</ul>
</li>
<li>
<input type="checkbox" id="footer-charts-and-pens" className={Styles.acInput}/>
<label htmlFor="footer-charts-and-pens" className={Styles.acLabel} >{chartsAndPens}</label>
<ul className={Styles.footercategory2}>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-sigma2-thermohygrographs/"  className="init-right line">{forSigma2Thermohygrographs}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-aurora90-3-thermohygrograph/"  className="init-right line">{forAurora903Thermohygrograph}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-sigma2-thermograph/"  className="init-right line">{forSigma2Thermograph}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-sigma2-hygrograph/"  className="init-right line">{forSigma2Hygrograph}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-sigma2-barograph/"  className="init-right line">{forSigma2Barograph}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-sigma2-remote-thermographs/"  className="init-right line">{forSigma2RemoteThermographs}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-mini-cube-thermohygrograph/"  className="init-right line">{forMiniCubeThermohygrograph}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-mini-star-thermohygrograph/"  className="init-right line">{forMiniStarThermohygrograph}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-mini-alpha-thermohygrograph/"  className="init-right line">{forMiniAlphaThermohygrograph}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-event-recorder/"  className="init-right line">{forEventRecorder}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-lmmc-temperature-recorder/"  className="init-right line">{forLmmcTemperatureRecorder}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/for-various-recorders/"  className="init-right line">{forVariousRecorders}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/charts-and-pens/cartridge-pens/"  className="init-right line">{cartridgePens}</LocalizedLink>
</li>
</ul>
</li>
<li>
<LocalizedLink to="/categories/other-accessories/" className="init-right line">{otherAccessories}</LocalizedLink>
</li>
<li>
<LocalizedLink to="/categories/discontinued-products/" className="init-right line">{discontinuedProducts}</LocalizedLink>
</li>
</ul>
);
};

export default FooterCategoryPC;