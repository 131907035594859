import React from "react"
import LocalizedLink from "../localizedLink"
import useTranslations from "../useTranslations"
import Styles from "./Footer.module.scss"
import {LocaleContext} from "../layout";
import FooterCategoryPC from "./FooterCategoryPC";
import FooterCategorySP from "./FooterCategorySP";

const Footer = () => {
    const {
        customerSupport,
        companyProfile,
        history,
        office,
        isoCertificate,
        jcssCertificate,
        privacy,
        companyInfo,
        contact,
        faq,
        catalogDownload,
        softDownload,
        documents,
        searchProducts
    } = useTranslations();

    const pathName = typeof window !== 'undefined' ? window.location.pathname : '';

    const { locale } = React.useContext(LocaleContext);

    return (
        <footer className={`flex center ${Styles.footer}`}>
            <div className={`${Styles.footerContents} flex`}>
                <div className={Styles.logoContainer} itemScope itemType="http://schema.org/Organization">
                    <LocalizedLink to={`/`}>
                        <div className={`img-box ${Styles.footerLogo}`} itemProp="logo"></div>
                    </LocalizedLink>
                    {
                        (pathName.indexOf('/en') !== -1) ?
                            <div className={Styles.txtbox} itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
                                <p itemProp="companyName" className={Styles.companyName}>Sato Keiryoki Mfg. Co., Ltd</p>
                                <p itemProp="postalCode"></p>
                                <p itemProp="streetAddress">3-4, Kanda-kajicho, Chiyoda-ku, Tokyo 101-0045 Japan</p>
                                <p itemProp="telephone">TEL: +81-(0)3-3254-8117</p>
                                <p itemProp="faxNumber">FAX: +81-(0)3-3254-8123</p>
                            </div>
                            : <div className={Styles.txtbox} itemProp="address" itemScope itemType="https://schema.org/PostalAddress">
                                <p itemProp="companyName" className={Styles.companyName}>株式会社佐藤計量器製作所</p>
                                <p itemProp="postalCode">〒101-0045</p>
                                <p itemProp="streetAddress">東京都千代田区神田鍛冶町三丁目４番地ｏａｋ神田鍛冶町</p>
                                <p itemProp="telephone">TEL: 03-3254-8111</p>
                                <p itemProp="faxNumber">FAX: 03-3254-8119</p>
                            </div>
                    }
                    <p className={`sp ${Styles.copySp}`} itemProp="owns">Copyright skSATO (SATO KEIRYOKI MFG. CO., LTD.)ALL Rights Reserved.</p>
                </div>
                <div className={`${Styles.footerLinks} flex between pc`}>
                    <section className={`${Styles.catalog} ${Styles.footerLinksItem}`}>
                        <h2 className={`footer-title`}>{searchProducts}</h2>
                        <FooterCategoryPC/>
                    </section>
                    <div className={`flex column between`}>
                        <section className={`${Styles.productInfo} ${Styles.footerLinksItem}`}>
                            <h2 className={`footer-title`}>{customerSupport}</h2>
                            <ul>
                                <li><LocalizedLink to={`/support/`}>{contact}</LocalizedLink></li>
                                <li><LocalizedLink to={`/support/faq/`}>{faq}</LocalizedLink></li>
                                <li><LocalizedLink to={`/support/catalog-download/`}>{catalogDownload}</LocalizedLink></li>
                                <li><LocalizedLink to={`/support/software-download/`}>{softDownload}</LocalizedLink> </li>
                                {locale === 'ja'?
                                    <li><LocalizedLink to={`/support/documents/`}>{documents}</LocalizedLink></li>:
                                    null
                                }
                            </ul>
                        </section>
                        <section className={`${Styles.campanyInfo} ${Styles.footerLinksItem}`}>
                            <h2 className={`footer-title`}>{companyInfo}</h2>
                            <ul>
                                <li><LocalizedLink to={`/company/`}>{companyProfile}</LocalizedLink></li>
                                {
                                    (pathName.indexOf('/en') !== -1) ?
                                        null:
                                        <li>
                                            <li><LocalizedLink to={`/company/history/`}>{history}</LocalizedLink></li>
                                        </li>
                                }
                                <li><LocalizedLink to={`/company/office/`}>{office}</LocalizedLink></li>
                                <li><LocalizedLink to={`/company/iso/`}>{isoCertificate}</LocalizedLink></li>
                                <li><LocalizedLink to={`/company/jcss/`}>{jcssCertificate}</LocalizedLink></li>
                                <li><LocalizedLink to={`/company/privacy/`}>{privacy}</LocalizedLink></li>
                            </ul>
                        </section>
                    </div>
                </div>

                <div className={`${Styles.footerLinks} ac sp`}>
                    <input className="ac-input" id="footer-company-info" name="footer-compoany-info" type="checkbox"/>
                    <label className="ac-label" htmlFor="footer-company-info">{companyInfo}</label>
                    <section className={`${Styles.catalog} ${Styles.footerLinksItem} ac-text ac-text-footer `}>
                        <ul>
                            <li><LocalizedLink to={`/company/`}>{companyProfile}</LocalizedLink></li>
                            <li><LocalizedLink to={`/company/history/`}>{history}</LocalizedLink></li>
                            <li><LocalizedLink to={`/company/office/`}>{office}</LocalizedLink></li>
                            <li><LocalizedLink to={`/company/iso/`}>{isoCertificate}</LocalizedLink></li>
                            <li><LocalizedLink to={`/company/jcss/`}>{jcssCertificate}</LocalizedLink></li>
                            <li><LocalizedLink to={`/company/privacy/`}>{privacy}</LocalizedLink></li>
                        </ul>
                    </section>
                </div>
                <div className={`${Styles.footerLinks} ac sp`}>

                    <input className="ac-input" id="footer-product-info" name="footer-product-info" type="checkbox"/>
                    <label className="ac-label" htmlFor="footer-product-info">{customerSupport}</label>
                    <section className={`${Styles.catalog} ${Styles.footerLinksItem} ac-text ac-text-footer`}>
                        <ul>
                            <li><LocalizedLink to={`/support/`}>{contact}</LocalizedLink></li>
                            <li><LocalizedLink to={`/support/faq/`}>{faq}</LocalizedLink></li>
                            <li><LocalizedLink to={`/support/catalog-download/`}>{catalogDownload}</LocalizedLink></li>
                            <li><LocalizedLink to={`/support/software-download/`}>{softDownload}</LocalizedLink> </li>
                            {locale === 'ja'?
                                <li><LocalizedLink to={`/support/documents/`}>{documents}</LocalizedLink></li>:
                                null
                            }
                        </ul>
                    </section>
                </div>

                <div className={`${Styles.footerLinks} ac sp`}>
                    <input className="ac-input" id="footer-product-search" name="footer-product-search" type="checkbox"/>
                    <label className="ac-label" htmlFor="footer-product-search">{searchProducts}</label>
                    <FooterCategorySP/>
                </div>
            </div>
            <p className={`pc ${Styles.copy}`}>Copyright skSATO (SATO KEIRYOKI MFG. CO., LTD.)ALL Rights Reserved.</p>
        </footer>
    )
}

export default Footer
